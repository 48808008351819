.ReportGoogleSheet{

  .title-container{
    padding: 20px 2%;
    margin-bottom: 10px;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
    background-color: #ffffff;
  }

  .report-table-container{
    padding: 20px 1%;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
    background-color: #ffffff;

    .report-table{
      min-width: 2500px;
    }

    //width: 2000px;

    //max-width: 100%;
    max-height: calc(100vh - 100px);
    overflow: auto;

  }

  .status-container {
    width: 130px;
  }

}
