.EditFormWhatsapp{
  margin: auto;
  padding: 30px 5%;
  background-color: #ffffff;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);

  .titulo {
    font-weight: bold;
    font-size: 18px;
    margin-top: 30px;
    margin-bottom: 10px;
  }

}

@media only screen and (max-width: 650px) {
  .EditFormWhatsapp{


    .actions-container{

      .btn-action{
        width: 100%;
        margin: 5px 0;
      }

    }

  }
}
