.SyncGoogleSheet{

  .text-header {
    padding: 20px 2%;
    margin-bottom: 10px;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
    background-color: #ffffff;
  }

  .actions{
    padding: 20px 2%;
    margin-bottom: 10px;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
    background-color: #ffffff;

    button{
      display: block;
      width: 300px;
      max-width: 100%;
      margin: 20px 0;
    }
  }


}
