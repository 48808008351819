@import '../../../assets/styles/inputField';
@import '../../../assets/styles/colors';
@import '../../../assets/styles/primaryButton';
@import '../../../assets/styles/secondaryButton';

.RegisterComponent {
  width: 90%;
  max-width: 340px;
  margin: 0 auto;

  .register-form {
    margin-top: 8px !important;
  }

  .form-title {
    font-size: 18px;
    color: $primaryColor !important;
  }

  .btnPreviousHalf {
    width: 34% !important;
    float: left !important;
  }

  .btnNextHalf {
    width: 64% !important;
    float: right !important;
  }

  .ant-checkbox-wrapper{
    margin-right: 10px;
  }

  .terms-spin {
    margin-left: 10px;
  }

  .terms-hyperlink {
    display: inline-block;
  }

  .terms-hyperlink > a {
    color: #053C5E !important;
  }

  .terms-hyperlink-on-loading > a {
    color: #535456 !important;
  }
}
