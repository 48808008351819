.BaseFormComponent {
  .base-background {
    height: 100vh;
  }

  .base-form-content {
    height: 100vh;
    overflow: auto;
    text-align: center;
    padding-top: 10%;
    padding-bottom: 30px;
    background-color: #f0f3f7;
  }

  .ant-tabs-nav-list {
    margin: 0 auto;
  }

  .logo-image {
    max-width: 90%;
    width: auto;
    height: auto;
    max-height: 40px;
    margin-bottom: 30px;
  }
  @media only screen and (min-width: 768px) {
    .logo-image {
      display: none;
    }
    .base-form-content {
      height: 100vh;
      display: flex;
      flex-direction: column;
      padding: 0 !important;
      justify-content: center;
    }
  }
}
