@import '../../assets/styles/colors';

.background-page {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  // background-image: linear-gradient(to right, $secondaryColor , $primaryColor);
  background-image: linear-gradient(to right, $primaryS1Color, $primaryColor, $primaryW1Color, #f0f3f7);
  background-size: cover;
  background-color: #f0f3f7;
  background-position: center;

  img{
    max-height: 140px;
  }
}
