.CompanyGroupList{
  padding: 20px 1%;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  background-color: #ffffff;

  .btn-add-new{
    float: right;
    margin-bottom: 20px;
  }

  .pagination-container{
    margin-top: 15px;
    text-align: right;
  }
}
