.BasicButton {
  height: 40px !important;
  border: none !important;
  color: white !important;
  border-radius: 4px !important;
  font-size: 14px !important;
  display: block !important;
  width: 100% !important;
  font-weight: 600 !important;
}
