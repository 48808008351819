@import './colors';
@import './basicButton';

.SecondaryButton {
  @extend .BasicButton;
  background-color: #B1B1B1 !important;
}

.SecondaryButton:focus{
  background-color: #B1B1B1 !important;
}

.SecondaryButton:hover {
  background-color: #8B8A8A !important;
}
