.ImportFormEmail{
  margin: auto;
  padding: 30px 5%;
  background-color: #ffffff;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);

  .actions-container{
    margin-top: 20px;
    text-align: right;

    .btn-action{
      min-width: 150px;
      margin-left: 10px;
    }
  }

}

@media only screen and (max-width: 650px) {
  .ImportFormEmail{


    .actions-container{

      .btn-action{
        width: 100%;
        margin: 5px 0;
      }

    }

  }
}
