$primaryColor: #09ACDA;
$primaryW1Color: #60C5E1; // Primary Color - Weaker #1
$primaryW2Color: #C6EEFA;

$primaryS1Color: #026986; // Primary Color - Stronger #1
$primaryS2Color: #013E4F;

$secondaryColor: #56CA65;
$terciaryColor: #FFB302;
$quaternaryColor: #1D43E0;

$errorColor: #F5222D;
$informationColor: #7EC8E3;
$successColor: #52C41A;
$warningColor: #FFE90B;