@import '../../assets/styles/inputField';
@import '../../assets/styles/primaryButton';
@import '../../assets/styles/secondaryButton';
@import '../../assets/styles/pageTitle';

.ChangePassword{
  margin: auto;
  padding: 30px 5%;
  background-color: #ffffff;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);

  .ChangePasswordForm {
    margin: 0 auto;
  }

  .actions-container{
    padding: 0 12px;
    margin-top: 20px;

    .ant-col {
      padding-right: 0 !important;
      padding-left: 0 !important;
    }

    .save {
      float: right;
      width: 45%;
    }

    .cancel {
      float: left;
      width: 45%;
    }
  }

}
