@import '../../assets/styles/inputField';
@import '../../assets/styles/colors';
@import '../../assets/styles/primaryButton';

.ForgotPassword {
  .form{
    width: 90%;
    max-width: 340px;
    margin: 0 auto;
  }

  .formTitle {
    font-weight: bold;
    color: $primaryColor;
    margin-top: 20px;
  }

  .formText {
    width: 300px;
    margin: 0 auto;
    color: $primaryColor;
    margin-bottom: 20px;
  }

}
