.EmailConfirmation {
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  .box {
    width: 300px;
    height: 300px;
    background: white;
    text-align: center;
    border: 1px solid rgba(0, 0, 0, 0.2);
    box-shadow: 5px 4px rgba(0, 0, 0, 0.1);
  }

  .box img {
    padding: 20px;
  }

  .box p{
    font-size: 14px;
  }

  .box strong {
    font-size: 16px;
  }

  .message {
    padding: 20px 0 0 0;
  }

  .logo-image {
    max-width: 90%;
    width: auto;
    height: auto;
    max-height: 40px;
    margin-bottom: 30px;
  }
}
