@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;700&family=Play&display=swap');

:root {
  --dark-blue-green: #004b2c;
  --dark-blue-green-9: rgba(0, 74, 43, 0.09);
  --light-olive: #a0c665;
  --very-light-pink: #e7e7e7;
  --butterscotch: #f9bd45;
  --butterscotch-85: rgba(250, 189, 69, 0.85);
  --dark-forest-green: #002817;
  --butterscotch-65: rgba(250, 189, 69, 0.65);
  --butterscotch-45: rgba(250, 189, 69, 0.45);
  --butterscotch-25: rgba(250, 189, 69, 0.25);
  --butterscotch-15: rgba(250, 189, 69, 0.15);
  --butterscotch-9: rgba(250, 189, 69, 0.09);
  --light-olive-green: #93bf4c;
  --pale-grey: #eff1f4;
  --light-olive-green-85: rgba(147, 190, 77, 0.85);
  --light-olive-green-65: rgba(147, 190, 77, 0.65);
  --light-olive-green-45: rgba(147, 190, 77, 0.45);
  --light-olive-green-25: rgba(147, 190, 77, 0.25);
  --light-olive-green-15: rgba(147, 190, 77, 0.15);
  --light-olive-green-9: rgba(147, 190, 77, 0.09);
  --dull-red: #a84b39;
  --dull-red-85: rgba(167, 75, 56, 0.85);
  --dull-red-65: rgba(167, 75, 56, 0.65);
  --dull-red-45: rgba(167, 75, 56, 0.45);
  --dull-red-25: rgba(167, 75, 56, 0.25);
  --dull-red-15: rgba(167, 75, 56, 0.15);
  --dull-red-9: rgba(167, 75, 56, 0.09);
  --black: #000000;
  --black-85: rgba(0, 0, 0, 0.85);
  --dark-blue-green-85: rgba(0, 74, 43, 0.85);
  --black-65: rgba(0, 0, 0, 0.65);
  --dark-blue-green-65: rgba(0, 74, 43, 0.65);
  --black-45: rgba(0, 0, 0, 0.45);
  --dark-blue-green-45: rgba(0, 74, 43, 0.45);
  --black-25: rgba(0, 0, 0, 0.25);
  --dark-blue-green-25: rgba(0, 74, 43, 0.25);
  --dark-blue-green-15: rgba(0, 74, 43, 0.15);
  --black-15: rgba(0, 0, 0, 0.15);
  --black-9: rgba(0, 0, 0, 0.09);
  --bright-orange: #ff5700;
  --white: #ffffff;
}

/* start of fade in effect on logo */
.fade-in {
  -webkit-animation: fadein 1s; /* Safari, Chrome and Opera > 12.1 */
     -moz-animation: fadein 1s; /* Firefox < 16 */
      -ms-animation: fadein 1s; /* Internet Explorer */
       -o-animation: fadein 1s; /* Opera < 12.1 */
          animation: fadein 1s;
}

@keyframes fadein {
  from { opacity: 0; }
  to   { opacity: 1; }
}

/* Firefox < 16 */
@-moz-keyframes fadein {
  from { opacity: 0; }
  to   { opacity: 1; }
}

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadein {
  from { opacity: 0; }
  to   { opacity: 1; }
}

/* Internet Explorer */
@-ms-keyframes fadein {
  from { opacity: 0; }
  to   { opacity: 1; }
}

/* Opera < 12.1 */
@-o-keyframes fadein {
  from { opacity: 0; }
  to   { opacity: 1; }
}
/* end of fade in effect on logo */



/* end of responsive style for menu mobile */


#components-layout-demo-custom-trigger .logo {
  height: 32px;
  background: rgba(255,255,255,.2);
  margin: 16px;
}


#HeaderComponent{
  padding: 6px;
  display: flex;
  flex-wrap: wrap;
  // height: auto !important;
  height: 54px !important;
  z-index: 10;
  line-height: 48px;

  .header-title-container{
    display: flex;
    vertical-align: top;
    // height: 60px;
    height: 40px;
    flex: 2;
    min-width: 100px;
  }
  .header-info{
    padding-left: 20px;
    display: flex;
    vertical-align: top;
    // height: 60px;
    height: 40px;
    min-width: 100px;
  }

  .HeaderComponent__settings-icon {
    color: #595959;
    font-size: 20px;
  }

  .trigger {
    font-size: 18px;
    // line-height: 72px;
    line-height: 48px;
    padding: 0 24px;
    cursor: pointer;
    transition: color .3s;
  }

  .trigger:hover {
    color: #1890ff;
  }

  #components-layout-demo-custom-trigger .trigger {
    font-size: 18px;
    line-height: 64px;
    padding: 0 24px;
    cursor: pointer;
    transition: color .3s;
  }

  #components-layout-demo-custom-trigger .trigger:hover {
    color: #1890ff;
  }

    background-color: #FAFAFA;
    -webkit-box-shadow: 0 4px 2px -1px rgba(0,0,0,0.2);
    -moz-box-shadow: 0 4px 2px -1px rgba(0,0,0,0.2);
    box-shadow: 0 4px 2px -1px rgba(0,0,0,0.2);


  .custom-header-menu-user-info {
    padding-right: 20px;
    //max-width: calc(100% - 100px);
    .ant-menu-submenu-arrow{
      padding-right: 40px;
    }
  }

  .header-title {
    font-size: 24px;
    font-weight: bold;
    min-width: 100px;
    line-height: 44px;
  }

  .dropdown-logout-container{
    padding-left: 10px;
    cursor: pointer;

    .dropdown-logout-info{
      display: inline-block;
      vertical-align: top;
    }

    .dropdown-logout{
      padding: 16px;
      cursor: pointer;
      vertical-align: top;
    }

  }
}

.ant-dropdown{
  .change-password{
    svg{
      vertical-align: middle;
      margin-left: 10px;
    }
  }
  .profile-edit{
    svg{
      vertical-align: middle;
      margin-left: 33px;
    }
  }
}

// Global styles

#main-content{
  padding: 15px 2%;
  height: 100%;
  //max-height: calc(100vh - 60px) !important;
}

.ant-layout-has-sider{
  max-height: 100vh !important;
}

#main-content{
  overflow: auto;
}

@media only screen and (min-width: 600px) {
  #main-content{
    overflow: auto;
  }
}

.btn-primary{
  background-color: #09ACDA !important;
  border: none !important;
  color: white !important;
  border-radius: 4px !important;
  font-size: 14px !important;
  font-weight: 600 !important;
  &:hover{
    background-color: #64bfd9 !important;
  }
}

.btn-secondary{
  background-color: #B1B1B1 !important;
  border: none !important;
  color: white !important;
  border-radius: 4px !important;
  font-size: 14px !important;
  font-weight: 600 !important;
  &:hover{
    background-color: #c4c4c4 !important;
  }
}


// ----------custom content scrollbar-------------
/* width */
::-webkit-scrollbar {
  width: 10px;
}
/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px #808080;
  border-radius: 10px;
  background: #e6e6e6;
}
/* Handle */
::-webkit-scrollbar-thumb {
  background: #a7a7a7;
  border-radius: 50px;
}
/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #8e8e8e;
}
// ----------custom content scrollbar-------------

.ant-form-item {
  margin-bottom: 10px;
}

@-webkit-keyframes rotating /* Safari and Chrome */ {
  from {
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes rotating {
  from {
    -ms-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -ms-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
.rotating {
  -webkit-animation: rotating 2s linear infinite;
  -moz-animation: rotating 2s linear infinite;
  -ms-animation: rotating 2s linear infinite;
  -o-animation: rotating 2s linear infinite;
  animation: rotating 2s linear infinite;
}
