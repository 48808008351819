.MonthReportList{
  padding: 20px 1%;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  background-color: #ffffff;

  .filters-container{
    margin-bottom: 20px;
    .button-filter {
      margin-top: 22px;
      margin-bottom: 10px;
    }

  }

  .btn-add-new{
    float: right;
    margin-bottom: 20px;
  }



  .action-container{
    display: flex;

    .action-item{
      cursor: pointer;
      margin: 0 5px;
      color: #000000;
    }

  }


  .pagination-container{
    margin-top: 15px;
    text-align: right;
  }
}

