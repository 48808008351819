@import '../../assets/styles/primaryButton';

.ResetPassword {
  .form{
    width: 90%;
    max-width: 340px;
    margin: 0 auto;
  }

}
