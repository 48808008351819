@import './colors';
@import './basicButton';

.PrimaryButton {
  @extend .BasicButton;
  background-color: $primaryColor !important;
}

.PrimaryButton:focus{
  background-color: $primaryColor !important;
}

.PrimaryButton:hover {
  background-color: $primaryS1Color !important;
}
