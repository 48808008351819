.notFound {
  height: 100%;
  align-items: center;
  display: flex;
  justify-content: center;

  .btn-go-back{
    width: 100%;
  }
}
